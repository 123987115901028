<template>
  <div class="container d-flex flex-column flex-root align-items-center mt-30">
    <div class="container--narrow ">
       <div class="logo-holder">
                <img src="media/logos/gmf-logo.png" />
            </div>
            <div v-if="status">
              <p style="color:red">{{ msg }}</p>
            </div>
            <div>
                <form
                    class="form"
                    novalidate="novalidate"
                    id="kt_login_signin_form"
                >
                    <div class="form-group">
                      <label class="font-size-h6 font-weight-bolder text-dark"
                          >Email</label
                      >
                      <div
                          id="example-input-group-1"
                          label=""
                          label-for="example-input-1"
                      >
                          <input
                          class="form-control form-control-solid h-auto py-5 px-6 rounded-lg login-input"
                          type="text"
                          name="email"
                          ref="email"
                          placeholder="Enter your email"
                          v-model="form.email"
                          />
                      </div>
                    </div>
                    <div class="pb-lg-0 pb-5">
                    <button
                        ref="kt_login_signin_submit"
                        class="btn btn-primary login-btn font-weight-bolder font-size-h6 px-15 py-4 my-3 mx-0 rounded-lg"
                    >
                        Reset
                    </button>
                    </div>
                </form>
            </div>
            <div>
                <p class="signup-text">
                    <a href="/#/login">Login to getmefood</a>
                </p>
            </div>
    </div>

  </div>
</template>

<style>
    body {
        background: #F8F7FA;
    }
    .logo-holder{
        text-align: center;
        margin-bottom: 30px;
    }
    .login-btn{
        width: 100%;
        background-color: #6666cc !important;
        border-color: #6666cc !important;
    }
    .container--narrow{
        max-width: 550px!important;
        margin: 0 auto;
        width: 100%;
    }
    .login-input{
        font-size: 18px;
        border-color: black !important;
        background-color: #fff !important;
    }
    .signup-text{
        font-size: 16px;
    }

</style>


<script>
import axios from "axios";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import ApiService from "@/core/services/api.service";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { FORGOT_PASSWORD, LOGOUT, LOGIN } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "forgotPassword",
  data() {
    return {
      state: "claim",
      form: {
        email: "",
      },
      status: false,
      msg: ""
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["isAuthenticated"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },

    logoImage() {
        return (
            process.env.BASE_URL + "media/logos/gmf-logo.png"
        );
    },
  },
  mounted() {
    // if (this.isAuthenticated) {
    //   if (this.$store.state.auth.user.orders / 1 === -1) {
    //     this.$router.push("/admin/businesses");
    //   } if (this.$store.state.auth.user.orders == '100') {
    //     this.$router.push("/business/wizard");
    //   } else {
    //     this.$router.push("/orders/today");
    //   }
    // }

    // if current user is authenticated redirect to dashboard
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        password_claime: {
          validators: {
            notEmpty: {
              message: "Password Confirm is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;

      this.form.token = this.$route.params.token;

      // clear existing errors
      //this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      //this.$store.dispatch(SET_COOKIE);
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "sanctum/csrf-cookie")
        .then((e) => {
          this.$store
            .dispatch(FORGOT_PASSWORD, this.form)
            // go to which page after successfully login
            .then((resp) => {
              //this.$router.push("/dashboard");
              this.status = true;
              this.msg = resp.message;
              console.log(resp);
             })
            .catch((error) => {
              this.status = true;
              $msg = "Please try again later.";
            });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        heightAuto: false,
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>
